import React, {Component} from 'react';
import {Route, withRouter} from 'react-router';
import {Redirect, Switch} from "react-router-dom";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {STATUS_SUCCESS,STATUS_ERROR} from "./common/utils/generic";
import {retrieveDomain} from "./actions/domain";
import Login from "./sections/login";
import {
  cleanValidateUser,
  getProfile,
  loginUser,
  loginUserGoogle,
  recoveryPassword,
  registerUser,
  reset,
  resetPassword,
  setUserBand,
  skipUpdate,
  updateUser,
  validateEmail,
  validateUser,
  validateLinkExpiration,
  getPromotionsDelete,
  getNextPromotionsDelete,
  getPagePromotionsDelete,
  getNumberPagePromotionsDelete, cleanStatusDeleteUser, deleteUser, deleteUserRepsol, validateLinkExpirationRepsol
} from "./actions/user";
import Register from "./sections/register/Register";
import NotFound from "./sections/notFound/notFound";
import Loader from "./sections/loading/Loader";
import MainRoot from "./sections/bederr/MainRoot";
import {toast} from "react-toastify";
import RecoveryPassword from "./sections/recoveryPassword";
import ResetPassword from "./sections/resetPassword";
import ValidateEmail from "./sections/validateEmail";
import Politics from "./sections/others/politics";
import TermsAndConditions from "./sections/others/termsAndConditions";
import CompleteData from "./sections/completeData";

import 'moment/locale/es';

import {Helmet} from "react-helmet";
import favicon from "./public/favicon.ico"
import logo192 from "./public/logo192.png"
import LandingMolitienda from "./sections/landing/landings/Molitienda/LandingMolitienda";
import LandingSanBorja from "./sections/landing/landings/SanBorja/LandingSanBorja";
import LandingOld from "./sections/landing/LandingOld";
import {getApp,getAppLandingModelTwo} from "./actions/app";
import {getPlaces, selectPlace} from "./actions/places";
import {
  getMoreProductsMenu,
  getProductsByPlace,
  getProductsMenu
} from "./actions/productsByPlace";
import Overlay from "./common/components/Overlay";
import Cookies from "./common/components/Cookies";
import PoliticsCookies from "./sections/cookies/PoliticsCookies";
import Landing from "./sections/landing/Landing";

import firebase from 'firebase';
import LandingModel2 from './sections/landing/landings/LandingModel2/LandingModel2';
import ValidateDeleteAccount from "./sections/deleteAccount/ValidateDeleteAccount";
import Error404 from './sections/notFound/Error404';
import CartMenuPage from "./sections/cartMenuPage";
import {getSectionsByCompany} from "./actions/sectionsToProducts";
import {selectArea} from "./actions/areas";
import ValidateRepsolDeleteAccount from "./sections/deleteAccount/ValidateRepsolDeleteAccount";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectLanding: false,
      respondeLanding: null,
      showOverlay: false,
      loadApp: false,
      loadFirebase: false,
      existFirebaseConfig: false,
    }
  }

  componentDidMount() {
    toast.configure();

    let url = window.location.hostname
    url = url.replace("www.", "")
    if (process.env.NODE_ENV === "development") {
      if (url) {
        if (process.env.REACT_APP_URL_REST === "https://api.bemaven.xyz/") {
          url = url.replace("bederr.local", "bemaven.xyz")
        } else {
          url = url.replace("bederr.local", "bederr.com")
        }
      }
      this.setState({loadApp: true})
      this.props.dispatch(retrieveDomain(url))
    } else {
      this.setState({loadApp: true})
      this.props.dispatch(retrieveDomain(url))
    }

    localStorage.setItem("redirectLanding", "false")


    let cookies = document.cookie.split(';');
    //let foundGa = -1
    let foundGuid = -1
    cookies.map((c, index) => {
      // if( (c.indexOf("_ga") > -1) && localStorage.getItem("cookie_ga")==="true"){
      //   foundGa = index
      // }
      if ((c.indexOf("cookie_guid") > -1) && localStorage.getItem("cookie_guid") === "true") {
        foundGuid = index
      }
      return true
    })

    if ((foundGuid === -1 && localStorage.getItem("cookie_guid") === "true")) {
      this.setState({showOverlay: true})
    }

    if (!Object.keys(localStorage).includes("cookie_ga") && !Object.keys(localStorage).includes("cookie_guid")) {
      this.setState({showOverlay: true})
    }

    if (localStorage.getItem("cookie_ga") === "false") {
      /*
      $(document).ready(function()
      {
        let aux = "<script async src=\"https://www.googletagmanager.com/gtag/js?id="+process.env.REACT_APP_F_MEASUREMENT_ID+"\"></script>\n" +
          "    <script>\n" +
          "       window['ga-disable-"+process.env.REACT_APP_F_MEASUREMENT_ID+"'] = true;\n" +
          "       window.dataLayer = window.dataLayer || [];\n" +
          "       function gtag(){dataLayer.push(arguments);}\n" +
          "       gtag('js', new Date());\n" +
          "\n" +
          "       gtag('config', '"+process.env.REACT_APP_F_MEASUREMENT_ID+"');\n" +
          "    </script>";
        $("body").append(aux);
      });
       */

      cookies.map(item => {
        if (item.includes('_ga')) {
          document.cookie = item + '=aceptada; max-age=' + 0 + ";"
        }
        return true;
      })
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      app: {app}
    } = this.props;

    if (app && app.landing_page !== false && localStorage.getItem("redirectLanding") === "false" && (this.props.history.location.pathname === "/" || this.props.history.location.pathname === "/landing")) {
      this.props.history.push(`/landing`)
      localStorage.setItem("redirectLanding", "true")
    }
    if (app && this.state.respondeLanding === null) {
      if (app.landing_page !== false) {
        this.setState({respondeLanding: true})
      } else {
        this.setState({respondeLanding: false})
      }
    }

    let cookies = document.cookie.split(';');
    let domain = ".bederr.com"
    if (localStorage.getItem("cookie_ga") === "false") {
      cookies.map(item => {
        if (item.includes('_ga')) {
          document.cookie = item.split('=')[0] + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; max-age=0; path=/;domain=' + domain + ";"
        }
        return true;
      })
    }

    if (!this.state.loadApp) {
      if (app === null || app === undefined) {
        let url = window.location.hostname
        url = url.replace("www.", "")
        if (process.env.NODE_ENV === "development") {
          if (url) {
            url = url.replace("bederr.local", "bederr.com")
          }
          this.props.dispatch(retrieveDomain(url))
        } else {
          this.props.dispatch(retrieveDomain(url))
        }
      }
      this.setState({loadApp: true})
    }
    if (!this.state.loadFirebase) {
      if (app) {
        this.setState({loadFirebase: true})
        let firebaseConf = null
        let config = {};
        if (app.firebase_settings) {

          let nFieldsRequired = 0
          if (app.firebase_settings.apiKey !== undefined && app.firebase_settings.apiKey !== null && app.firebase_settings.apiKey.length !== 0) {
            config['apiKey'] = app.firebase_settings.apiKey
            nFieldsRequired += 1
          }
          if (app.firebase_settings.authDomain !== undefined && app.firebase_settings.authDomain !== null && app.firebase_settings.authDomain.length !== 0) {
            config['authDomain'] = app.firebase_settings.authDomain
            nFieldsRequired += 1
          }
          if (app.firebase_settings.databaseURL !== undefined && app.firebase_settings.databaseURL !== null && app.firebase_settings.databaseURL.length !== 0) {
            config['databaseURL'] = app.firebase_settings.databaseURL
            nFieldsRequired += 1
          }
          if (app.firebase_settings.projectId !== undefined && app.firebase_settings.projectId !== null && app.firebase_settings.projectId.length !== 0) {
            config['projectId'] = app.firebase_settings.projectId
            nFieldsRequired += 1
          }
          if (app.firebase_settings.storageBucket !== undefined && app.firebase_settings.storageBucket !== null && app.firebase_settings.storageBucket.length !== 0) {
            config['storageBucket'] = app.firebase_settings.storageBucket
            nFieldsRequired += 1
          }
          if (app.firebase_settings.messagingSenderId !== undefined && app.firebase_settings.messagingSenderId !== null && app.firebase_settings.messagingSenderId.length !== 0) {
            config['messagingSenderId'] = app.firebase_settings.messagingSenderId
            nFieldsRequired += 1
          }
          if (app.firebase_settings.appId !== undefined && app.firebase_settings.appId !== null && app.firebase_settings.appId.length !== 0) {
            config['appId'] = app.firebase_settings.appId
            nFieldsRequired += 1
          }
          if (app.firebase_settings.measurementId !== undefined && app.firebase_settings.measurementId !== null && app.firebase_settings.measurementId.length !== 0) {
            config['measurementId'] = app.firebase_settings.measurementId
            nFieldsRequired += 1
          }

          if (nFieldsRequired > 5) {
            firebaseConf = firebase.initializeApp(config);

            if (firebaseConf != null) {
              this.setState({existFirebaseConfig: true})
              firebase.analytics()
              let remoteConfig = firebase.remoteConfig()
              remoteConfig.defaultConfig = ({})
              remoteConfig.activate()
              remoteConfig.fetchAndActivate()
                .then((out) => {

                  localStorage.setItem("colors_main_color",remoteConfig.getString("colors_main_color") )
                  localStorage.setItem("colors_menu_color",remoteConfig.getString("colors_menu_color") )
                  this.setState({loading: false})
                })
                .catch((err) => {
                  this.setState({loading: true})
                });
            }
          }
        }
        else if (app.site_firebase_settings) {

            let nFieldsRequired = 0
            if (app.site_firebase_settings.apiKey !== undefined && app.site_firebase_settings.apiKey !== null && app.site_firebase_settings.apiKey.length !== 0) {
              config['apiKey'] = app.site_firebase_settings.apiKey
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.authDomain !== undefined && app.site_firebase_settings.authDomain !== null && app.site_firebase_settings.authDomain.length !== 0) {
              config['authDomain'] = app.site_firebase_settings.authDomain
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.databaseURL !== undefined && app.site_firebase_settings.databaseURL !== null && app.site_firebase_settings.databaseURL.length !== 0) {
              config['databaseURL'] = app.site_firebase_settings.databaseURL
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.projectId !== undefined && app.site_firebase_settings.projectId !== null && app.site_firebase_settings.projectId.length !== 0) {
              config['projectId'] = app.site_firebase_settings.projectId
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.storageBucket !== undefined && app.site_firebase_settings.storageBucket !== null && app.site_firebase_settings.storageBucket.length !== 0) {
              config['storageBucket'] = app.site_firebase_settings.storageBucket
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.messagingSenderId !== undefined && app.site_firebase_settings.messagingSenderId !== null && app.site_firebase_settings.messagingSenderId.length !== 0) {
              config['messagingSenderId'] = app.site_firebase_settings.messagingSenderId
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.appId !== undefined && app.site_firebase_settings.appId !== null && app.site_firebase_settings.appId.length !== 0) {
              config['appId'] = app.site_firebase_settings.appId
              nFieldsRequired += 1
            }
            if (app.site_firebase_settings.measurementId !== undefined && app.site_firebase_settings.measurementId !== null && app.site_firebase_settings.measurementId.length !== 0) {
              config['measurementId'] = app.site_firebase_settings.measurementId
              nFieldsRequired += 1
            }

            if (nFieldsRequired > 5) {
              firebaseConf = firebase.initializeApp(config);

              if (firebaseConf != null) {
                this.setState({existFirebaseConfig: true})
                firebase.analytics()
                let remoteConfig = firebase.remoteConfig()
                remoteConfig.defaultConfig = ({})
                remoteConfig.activate()
                remoteConfig.fetchAndActivate()
                  .then((out) => {
                    localStorage.setItem("colors_main_color",remoteConfig.getString("colors_main_color") )
                    localStorage.setItem("colors_menu_color",remoteConfig.getString("colors_menu_color") )
                    this.setState({loading: false})
                  })
                  .catch((err) => {
                    this.setState({loading: true})
                  });
              }
            }
          }

      }
    }
  }

  render() {
    const {
      app: {status, app, company, appFields,landingModelTwoResults,statusAppLandingModelTwo, statusAppFields},
      auth: {authStatus, registerStatus, errorAuth, errorRegister, userBand,
        validateStatus: validateUserStatus,
        errorValidate: errorUserValidate,
        validate},
      user: {
        user,
        errorUpdate,
        updateStatus,
        errorRecovery,
        recoveryStatus,
        resetStatus,
        errorReset,
        validateStatus,
        errorValidate,
        validateDeleteStatus,
        errorValidateDelete,

        results: promotionsDeleteResults,
        entities: promotionsDeleteEntities,
        count: countPromotionsDelete,
        nextPage: nextPagePromotionsDelete,
        previousPage: previousPagePromotionsDelete,
        listStatus: listStatusPromotionsDelete,
        statusDeleteUser
      },
      places: {
        results: placesResults,
        entities: placesEntities,
        listStatus: listPlacesStatus,
        selectedPlace
      },
      productsPlace: {
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,

        entitiesMenu: productsMenuEntities,
        resultsMenu: productsMenuResults,
        listProductsMenuStatus,
        listNextStatusMenu,
        nextPageMenu,
        countMenu,
        productsMenuCurrent,
        productsMenuCurrentStatus,
      },
      sectionsProducts: {
        entitiesCategoriesByCompany: sectionsEntities,
        resultsCategoriesByCompany: sectionsResults,
        listStatusCategoriesByCompany: sectionsStatus,
      },
    } = this.props;


    if (status === STATUS_SUCCESS && app && this.state.respondeLanding !== null) {
      return (
        <div>
          {
            <Helmet>
              <meta charSet="utf-8"/>
              <link rel="icon" href={(app.favicon !== undefined && app.favicon) ? app.favicon : favicon}/>
              <meta name="description"
                    content={(company.description !== undefined && company.description) ? company.description : "Los mejores beneficios y productos en un solo lugar"}/>
              <link rel="apple-touch-icon"
                    href={(company.logo !== undefined && company.logo) ? company.logo : logo192}/>
              <link
                href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=latin-ext"
                rel="stylesheet"/>
              <title>{(company.name !== undefined && company.name && company.name !== "") ? company.name : "Bederr"}</title>
            </Helmet>
          }
          {
            process.env.REACT_APP_URL_REST && process.env.REACT_APP_URL_REST === "https://api.bemaven.xyz/" &&
            <div className="corner" style={{
              zIndex: 10000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transformOrigin: 'center center',
              position: 'fixed',
              backgroundColor: 'rgb(0, 163, 0)',
              color: 'rgb(255, 255, 255)',
              fontSize: '18px',
              height: '60px',
              width: '120px',
              top: '-8.7868px',
              transform: 'rotate(-45deg)',
              left: '-38.7868px'
            }}><br/><span style={{marginTop: '15px'}}>TEST</span></div>
          }
          {
            this.state.showOverlay &&
            <Overlay></Overlay>
          }
          <Switch>
            <Route exact path="/cookies/" render={(props) =>
              <PoliticsCookies
                setOverlay={e => this.setState({showOverlay: e})}
              />}
            />

            <Route exact path='/carta' render={(props) =>
                <CartMenuPage
                  {...props}
                  app={app}
                  status={status}
                  user={user}
                  updateStatus={updateStatus}
                  errors={errorUpdate}
                  skipUpdate={() => this.props.dispatch(skipUpdate())}
                  getProfile={() => this.props.dispatch(getProfile())}
                  updateUser={(data) => this.props.dispatch(updateUser(data))}



                  sections={sectionsResults && sectionsEntities && sectionsResults.map(
                    (cUid) => sectionsEntities[cUid]
                  )}
                  sectionsStatus={sectionsStatus}
                  selectPlace={(place) => this.props.dispatch(selectPlace(place))}
                  selectArea={(area) => this.props.dispatch(selectArea(area))}
                  selectedPlace={selectedPlace}
                  listPlacesStatus={listPlacesStatus}
                  places={placesResults.map(pUid => placesEntities[pUid])}
                  productsPlace={productsMenuResults.map(
                    (cUid) => productsMenuEntities[cUid]
                  )}
                  count={countMenu}
                  company={company}
                  nextPageProducts={nextPageMenu}
                  listProductsByPlaceStatus={listProductsMenuStatus}
                  listNextProductsByPlaceStatus={listNextStatusMenu}
                  productsByPlaceCurrent={productsMenuCurrent}
                  productsByPlaceCurrentStatus={productsMenuCurrentStatus}
                  getPlaces={() => {
                    if (company)
                      this.props.dispatch(getPlaces({company: company.uid}))
                  }}
                  getProductsByPlace={( params = {company: company.uid})=> this.props.dispatch(getProductsMenu({company: company.uid, ...params}))}
                  getSectionsToProducts={() => {
                    if (company)
                      this.props.dispatch(getSectionsByCompany(company.uid))
                  }}
                  getMoreProductsByPlace={(url) => this.props.dispatch(getMoreProductsMenu(url))}
                />
            }
            />

            <Route exact path="/recovery-password/" render={(props) => {
              return authStatus !== STATUS_SUCCESS ?
                <RecoveryPassword
                  {...props}
                  app={app}
                  recoveryStatus={recoveryStatus}
                  errors={errorRecovery}
                  recoveryPassword={(data) => this.props.dispatch(recoveryPassword(data))}
                /> : <Redirect to={'/'}/>
            }}
            />

            <Route exact path="/reset-password/:uidUser/:token" render={(props) => {
              return authStatus !== STATUS_SUCCESS ?
                <ResetPassword
                  {...props}
                  app={app}
                  resetStatus={resetStatus}
                  errors={errorReset}
                  resetPassword={(data, uidUser, token) => this.props.dispatch(resetPassword(data, uidUser, token))}
                /> : <Redirect to={'/'}/>
            }}
            />

            <Route exact path="/verification-email/:uidApp/:uidUser/:token" render={(props) =>
              <ValidateEmail
                {...props}
                app={app}
                validateStatus={validateStatus}
                errors={errorValidate}
                validateEmail={(data, uidApp, uidUser, token) => this.props.dispatch(validateEmail(data, uidApp, uidUser, token))}
              />}
            />

            <Route exact path="/repsol/me/delete/:uidApp/:uidUser/:token" render={(props) => {
              return (window.location.hostname.includes("repsol")  ?
                <ValidateRepsolDeleteAccount
                  {...props}
                  app={app}
                  validateStatus={validateDeleteStatus}
                  errors={errorValidateDelete}
                  promotionsDeleteResults={promotionsDeleteResults}
                  promotionsDeleteEntities={promotionsDeleteEntities}
                  count={countPromotionsDelete}
                  nextPage={nextPagePromotionsDelete}
                  previousPage={previousPagePromotionsDelete}
                  listStatus={listStatusPromotionsDelete}
                  cleanStatusDeleteUser={() => this.props.dispatch(cleanStatusDeleteUser())}
                  deleteUser={(uidApp, uidUser, token) => this.props.dispatch(deleteUserRepsol(uidApp, uidUser, token))}
                  validateLinkExpiration={(uidApp, uidUser, token) => this.props.dispatch(validateLinkExpirationRepsol(uidApp, uidUser, token))}
                  getPromotionsDelete={(uidUser, token) => this.props.dispatch(getPromotionsDelete(uidUser, token))}
                  getNextPromotionsDelete={(url) => this.props.dispatch(getNextPromotionsDelete(url))}
                  getPagePromotionsDelete={(url) => this.props.dispatch(getPagePromotionsDelete(url))}
                  getNumberPage={(uidUser, pageUid, token) => this.props.dispatch(getNumberPagePromotionsDelete(uidUser, pageUid, token))}
                  statusDeleteUser={statusDeleteUser}
                /> : <Redirect to={`/${(window.location).toString().split('/').slice(4).join('/')}`}/>)
            }}
            />

            <Route exact path="/me/delete/:uidApp/:uidUser/:token" render={(props) => {
              return (!window.location.hostname.includes("repsol")  ?
                <ValidateDeleteAccount
                  {...props}
                  app={app}
                  validateStatus={validateDeleteStatus}
                  errors={errorValidateDelete}
                  promotionsDeleteResults={promotionsDeleteResults}
                  promotionsDeleteEntities={promotionsDeleteEntities}
                  count={countPromotionsDelete}
                  nextPage={nextPagePromotionsDelete}
                  previousPage={previousPagePromotionsDelete}
                  listStatus={listStatusPromotionsDelete}
                  cleanStatusDeleteUser={() => this.props.dispatch(cleanStatusDeleteUser())}
                  deleteUser={(uidUser, token) => this.props.dispatch(deleteUser(uidUser, token))}
                  validateLinkExpiration={(uidUser, token) => this.props.dispatch(validateLinkExpiration(uidUser, token))}
                  getPromotionsDelete={(uidUser, token) => this.props.dispatch(getPromotionsDelete(uidUser, token))}
                  getNextPromotionsDelete={(url) => this.props.dispatch(getNextPromotionsDelete(url))}
                  getPagePromotionsDelete={(url) => this.props.dispatch(getPagePromotionsDelete(url))}
                  getNumberPage={(uidUser, pageUid, token) => this.props.dispatch(getNumberPagePromotionsDelete(uidUser, pageUid, token))}
                  statusDeleteUser={statusDeleteUser}
                /> : <Redirect to={`/repsol/${(window.location).toString().split('/').slice(3).join('/')}`}/>)
            }}
            />


            {
              authStatus !== STATUS_SUCCESS && (app.allow_anonymous_navigation !== true) &&
              <Route exact path='/politics' render={(props) =>
                <Politics
                  app={app}
                  appFields={appFields}
                  statusAppFields={statusAppFields}
                  authStatus={authStatus}
                  getApp={(uid) => this.props.dispatch(getApp(uid))}
                />
              }
              />
            }
            {
              authStatus !== STATUS_SUCCESS && (app.allow_anonymous_navigation !== true) &&
              <Route exact path='/terms-and-conditions' render={(props) =>
                <TermsAndConditions
                  app={app}
                  appFields={appFields}
                  statusAppFields={statusAppFields}
                  authStatus={authStatus}
                  getApp={(uid) => this.props.dispatch(getApp(uid))}
                />
              }
              />
            }
            <Route exact path="/register/" render={(props) => {
              return (app && app.empty_page === false) && authStatus !== STATUS_SUCCESS ?
                <Register
                  {...props}
                  app={app}
                  userBand={userBand}
                  authStatus={registerStatus}
                  errors={errorRegister}
                  reset={() => this.props.dispatch(reset())}
                  registerUser={(data) => this.props.dispatch(registerUser(data))}
                  setUserBand={(user) => this.props.dispatch(setUserBand(user))}
                /> : <Redirect to={'/'}/>
            }}
            />
            <Route exact path="/login" render={(props) => {
              return (app && app.empty_page === false) && authStatus !== STATUS_SUCCESS ?
                <Login
                  {...props}
                  app={app}
                  userBand={userBand}
                  authStatus={authStatus}
                  errors={errorAuth}
                  loginUser={(data) => this.props.dispatch(loginUser(data))}
                  loginUserGoogle={(data) => this.props.dispatch(loginUserGoogle(data))}
                  validateUser={(data) => this.props.dispatch(validateUser(data))}
                  cleanValidateUser={() => this.props.dispatch(cleanValidateUser())}
                  reset={() => this.props.dispatch(reset())}
                  setUserBand={(user) => this.props.dispatch(setUserBand(user))}
                  validateUserStatus={validateUserStatus}
                  errorUserValidate={errorUserValidate}
                  validate={validate}
                /> : <Redirect to={'/'}/>
            }}
            />
            <Route path='/complete-info' render={(props) => {
              return authStatus === STATUS_SUCCESS ?
                <CompleteData
                  {...props}
                  app={app}
                  user={user}
                  updateStatus={updateStatus}
                  errors={errorUpdate}
                  skipUpdate={() => this.props.dispatch(skipUpdate())}
                  getProfile={() => this.props.dispatch(getProfile())}
                  updateUser={(data) => this.props.dispatch(updateUser(data))}
                /> : <Redirect to={'/'}/>
            }}
            />
            <Route path="/landing" render={(props) => {
              return (app && app.landing_page !== false) ?
                app.subdomain === "marketlamolina" ?
                  <LandingMolitienda
                    {...props}
                    app={app}
                    getPlaces={() => {
                      if (company)
                        this.props.dispatch(getPlaces({company: company.uid}))
                    }}
                    existFirebaseConfig={this.state.existFirebaseConfig}
                    places={placesResults.map(pUid => placesEntities[pUid])}
                    productsPlace={productsByPlaceResults.map(cUid => productsByPlaceEntities[cUid])}
                    getProductsByPlace={(uidPlace, params = []) => this.props.dispatch(getProductsByPlace(uidPlace, params))}
                  /> :
                  app.subdomain === "marketsanborja" ?
                    <LandingSanBorja
                      {...props}
                      app={app}
                      getPlaces={() => {
                        if (company)
                          this.props.dispatch(getPlaces({company: company.uid}))
                      }}
                      existFirebaseConfig={this.state.existFirebaseConfig}
                      places={placesResults.map(pUid => placesEntities[pUid])}
                      productsPlace={productsByPlaceResults.map(cUid => productsByPlaceEntities[cUid])}
                      getProductsByPlace={(uidPlace, params = []) => this.props.dispatch(getProductsByPlace(uidPlace, params))}
                    /> :
                    app.landing_page_type==="MODEL_2" ?
                    <LandingModel2
                      {...props}
                      app={app}
                      getAppLandingModelTwo={() => this.props.dispatch(getAppLandingModelTwo(app.uid))}
                      landingModelTwoResults={landingModelTwoResults}
                      statusAppLandingModelTwo={statusAppLandingModelTwo}
                      getPlaces={() => {
                        if (company)
                          this.props.dispatch(getPlaces({company: company.uid}))
                      }}
                      places={placesResults.map(pUid => placesEntities[pUid])}
                      productsPlace={productsByPlaceResults.map(cUid => productsByPlaceEntities[cUid])}
                      getProductsByPlace={(uidPlace, params = []) => this.props.dispatch(getProductsByPlace(uidPlace, params))}
                      path={app.allowed_companies && app.allowed_companies.length > 0 ? "/c/:cUid/" : "/"}
                    />:
                    <Landing
                    {...props}
                    app={app}
                    getPlaces={() => {
                      if (company)
                        this.props.dispatch(getPlaces({company: company.uid}))
                    }}
                    places={placesResults.map(pUid => placesEntities[pUid])}
                    productsPlace={productsByPlaceResults.map(cUid => productsByPlaceEntities[cUid])}
                    getProductsByPlace={(uidPlace, params = []) => this.props.dispatch(getProductsByPlace(uidPlace, params))}
                  />
                : <Redirect to={'/'}/>
            }}/>

            <Route path="/" render={(props) => {
              return (app && app.empty_page === false) ?
                ((app && app.allow_anonymous_navigation) || authStatus === STATUS_SUCCESS) ?
                  <div key="div-main-root" id="wrapper">
                    <MainRoot
                      path={app.allowed_companies && app.allowed_companies.length > 0 ? "/c/:cUid/" : "/"}
                      key="main-root"
                      {...this.props}
                    />
                  </div>
                  : <Redirect to={'/login'}/>
                :
                <LandingOld
                  {...props}
                  app={app}
                />
            }}/>

            <Route path='*' render={NotFound}/>
          </Switch>
          {
            this.state.showOverlay &&
            <Cookies setOverlay={e => this.setState({showOverlay: e})}></Cookies>
          }
        </div>
      );
    }
 
    if (status === STATUS_ERROR && app==null) {
      return  <Error404 />
    }
    else {
      return <Loader type={"page"}/>;
    }
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(connect((state) => ({
  app: state.app,
  auth: state.auth,
  places: state.places,
  menus: state.menus,
  user: state.user,
  sections: state.sections,
  promotions: state.promotions,
  programs: state.programs,
  placesPromotion: state.placesPromotion,
  relatedPromotion: state.relatedPromotion,
  productsPlace: state.productsPlace,
  sectionsProducts: state.sectionsProducts,
  pickupSchedule: state.pickupSchedule,
  popup: state.popup,
  cart: state.cart,
  areas: state.areas,
  redemptions: state.redemptions,
  coupon: state.coupon,
  subscriptions: state.subscriptions,
  ads: state.ads,
  raffles: state.raffles,
  delivery: state.delivery,
  orders: state.orders,
  polls: state.polls,
  events: state.events
}))(App));